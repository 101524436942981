'use strict';

import {on, find, findAll, findAllIn, closest, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from '@elements/data-set-utils';
import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';

let defaultOptions = {
    scrollOffset: 70
};
let defaultSelectors = {
    base: '.js-smoothscroll',
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {

    onFind(selectors.base, function (baseElement) {
        createSmoothScroll(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

}

export function createSmoothScroll(smoothScroll, options = defaultOptions, selectors = defaultSelectors) {

    let elementOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('smoothscroll', smoothScroll)
    };


    const anchors = findAllIn(defaultSelectors.link, smoothScroll);

    setTimeout(function() {
        setInitialPosition(smoothScroll, options, selectors);
    }, 1);


    anchors.forEach(element => {
        element.addEventListener('click', function (evt) {
            evt.preventDefault();
            const
                anchor = element,
                target =  findIn(element.hash, smoothScroll);

            let offset = anchor.dataset.scrollOffset ?  anchor.dataset.scrollOffset : elementOptions.scrollOffset;

            smoothscroll(element, target, offset, selectors, anchor.offsetTop)
        })
    });
}

export function setOffset(offset) {
    defaultOptions.scrollOffset = offset;
}

export function smoothscroll(el, target, offset = defaultOptions.scrollOffset, selectors, anchorOffset) {
    let collapseParent = closest('.collapse:not(.show)', target);
    if (collapseParent) {
        let targetId = "#" + collapseParent.getAttribute('id');
        let triggerSelectors = findAll('[data-toggle="collapse"]').filter(el => el.dataset.target === targetId || el.getAttribute('href') === targetId);

        if(triggerSelectors) {
            triggerSelectors.map(trigger => {
                new Collapse(trigger).show();
                on('shown.bs.collapse', function () {
                    scrollTo(el, collapseParent.parentElement, offset, anchorOffset);
                }, collapseParent);
            })
        }
    } else {
        scrollTo(el, target, offset, anchorOffset)
    }
}

function scrollTo(el, target, offset, anchorOffset) {
    // let time = target.offsetTop - anchorOffset > 0 ? (target.offsetTop - anchorOffset)/3 : ((target.offsetTop - anchorOffset)*-1)/3;

    let scrollOffset = target.offsetTop - offset;

    const fixedOffset = scrollOffset.toFixed(),
        onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);
                target.focus();
                if(el){
                    history.replaceState({}, '', el.hash);
                }
                if (target === document.activeElement) {
                    return false;
                } else {
                    target.focus();
                }
            }
        };

    window.addEventListener('scroll', onScroll);
    window.scrollTo({
        top: scrollOffset,
        behavior: 'smooth'
    });

}

function setInitialPosition(scope, options, selectors) {
    let hash = window.location.hash;
    if(hash) {
        const
            pattern = new RegExp(hash),
            anchors = findAllIn(selectors.link, scope),
            target = find(hash);

        let offset = options.scrollOffset;

        anchors.forEach((el) => {
            offset = pattern.test(el.getAttribute('href')) && el.getAttribute('data-'+selectors.dataOffset) ? el.getAttribute('data-'+selectors.dataOffset) : offset;
        });

        smoothscroll(this, target, offset, defaultSelectors, 0)
    }
}
